/**
 * A library for media analysis using Web APIs.
 *
 * @packageDocumentation
 */
export * from './audio';
export * from './video';
export * from './visual';
export * from './types';
export * from './math';
export * from './process';
export * from './typeGuards';
export {createAsyncCallbackLoop} from './utils';
export * from './benchUtils';

export const urls = {
    denoise: () => new URL('../worklets/denoise.worklet.js', import.meta.url),
};
