import React, {useEffect, useState} from 'react';
import cx from 'classnames';

import {
    getCalculateAudioMeterStateFn,
    getLinearGradient,
} from '../../utils/audioMeter';

import {InputControl} from './InputControl.view';

import styles from './AudioMeterButton.module.scss';

const calculateAudioMeterState = getCalculateAudioMeterStateFn();

export const AudioMeterButton: React.FC<
    React.ComponentProps<typeof InputControl> & {
        level?: number;
        levelIndicateDisabledState?: boolean;
    }
> = ({level, isActive, levelIndicateDisabledState = false, ...props}) => {
    const [linearGradient, setLinearGradient] = useState('');

    useEffect(() => {
        let ignore = false;

        void calculateAudioMeterState(level)
            .then(audioState => {
                if (ignore) {
                    return;
                }

                setLinearGradient(
                    getLinearGradient({
                        ...audioState,
                        /*
                         * isActive is true when microphone is muted,
                         * enable the gradient only when mic is unmuted
                         */
                        enabled: !isActive,
                    }),
                );
            })
            .catch(() => {
                // prevents console promise error on cancel (reject)
            });

        return () => {
            ignore = true;
            calculateAudioMeterState?.cancel();
        };
    }, [isActive, level]);

    return (
        <InputControl
            style={
                !isActive || levelIndicateDisabledState
                    ? {
                          backgroundImage: linearGradient,
                      }
                    : {}
            }
            className={cx({
                [styles.enabled]: !isActive,
                [styles.disabled]: isActive && levelIndicateDisabledState,
            })}
            variant="transparentAlternative"
            size="large"
            isActive={isActive}
            {...props}
        />
    );
};
