import React from 'react';
import {useTranslation} from 'react-i18next';

import type {LogoSizeModifier} from '@pexip/components';
import {IconTypes, Icon} from '@pexip/components';

import type {DeniedDevice, DeviceStatusInfo} from '../types';

export const useInputsState = ({
    isAudioInputMuted,
    isVideoInputMuted,
    inputStatusInfo,
    iconSize,
}: {
    isVideoInputMuted: boolean;
    isAudioInputMuted: boolean;
    inputStatusInfo: DeviceStatusInfo;
    iconSize: LogoSizeModifier;
}) => {
    const {t} = useTranslation();
    const warning = inputStatusInfo.warning;
    const hasAudioError = hasInputError('audio', inputStatusInfo.error);
    const hasVideoError = hasInputError('video', inputStatusInfo.error);
    const hasAudioVideoError = hasInputError(
        'audio-video',
        inputStatusInfo.error,
    );

    const isAudioIconMuted =
        hasAudioVideoError || hasAudioError || isAudioInputMuted;
    const isVideoIconMuted =
        hasAudioVideoError || hasVideoError || isVideoInputMuted;

    const audioInputAction = isAudioIconMuted
        ? t('media.unmute', 'Unmute')
        : t('media.mute', 'Mute');
    const videoInputAction = isVideoIconMuted
        ? t('media.on', 'on')
        : t('media.off', 'off');

    const audioInputLabel = t('media.microphone-action', {
        defaultValue: '{{audioInputAction}} microphone',
        audioInputAction,
    });
    const videoInputLabel = t('media.camera-action', {
        defaultValue: 'Turn camera {{videoInputAction}}',
        videoInputAction,
    });

    return {
        audioProps: {
            hasError: hasAudioError,
            hasAudioVideoError: hasAudioVideoError,
            isActive: isAudioInputMuted,
            isMuted: isAudioInputMuted,
            'aria-label': audioInputLabel,
            icon: isAudioIconMuted ? (
                <Icon source={IconTypes.IconMicrophoneOff} size={iconSize} />
            ) : (
                <Icon source={IconTypes.IconMicrophoneOn} size={iconSize} />
            ),
        },
        videoProps: {
            hasError: hasVideoError,
            hasAudioVideoError: hasAudioVideoError,
            isActive: isVideoInputMuted,
            isMuted: isVideoInputMuted,
            'aria-label': videoInputLabel,
            icon: isVideoIconMuted ? (
                <Icon source={IconTypes.IconVideoOff} size={iconSize} />
            ) : (
                <Icon source={IconTypes.IconVideoOn} size={iconSize} />
            ),
        },
        warning,
        isAudioIconMuted,
        isVideoIconMuted,
    };
};

export const hasInputError = (
    errorType: 'video' | 'audio' | 'audio-video',
    deniedState?: DeniedDevice,
) => deniedState === errorType;
