import type {
    VideoProcessor,
    SegmentationModel,
    SegmentationTransform,
    Size,
} from '@pexip/media-processor';
import {
    createVideoProcessor,
    createCanvasTransform,
    createVideoTrackProcessor,
    createVideoTrackProcessorWithFallback,
    isRenderEffects,
    isSegmentationModel,
} from '@pexip/media-processor';
import type {MediaDeviceRequest} from '@pexip/media-control';
import {
    muteStreamTrack,
    extractConstraintsWithKeys,
    getValueFromConstrainNumber,
} from '@pexip/media-control';
import {isEmpty} from '@pexip/utils';

import type {
    Process,
    Media,
    VideoRenderParams,
    Segmenters,
    VideoStreamTrackProcessorAPIs,
    VideoContentHint,
} from './types';
import {
    shallowCopy,
    wrapToJSON,
    applyExtendedConstraints,
    getBlurKernelSize,
} from './utils';
import {logger, proxyWithLog} from './logger';
import {isVideoContentHint} from './typeGuard';

interface ProcessorDeps {
    videoProcessor?: () => VideoProcessor;
    transformer?: SegmentationTransform;
    segmenters: Segmenters;
    videoSegmentationModel?: SegmentationModel;
}

interface VideoStreamProcessOptions
    extends Partial<VideoRenderParams>,
        Omit<ProcessorDeps, 'videoProcessor'> {
    /**
     * What API to use for processing the MediaStreamTrack
     * `stream` - Use MediaStreamTrackProcessor, when available
     * `canvas` - Use Canvas
     */
    trackProcessorAPI?: () => VideoStreamTrackProcessorAPIs;
    /**
     * Whether or to enable this processor
     */
    shouldEnable: () => boolean;
    /**
     * Callback when error occurs
     */
    onError?: (error: Error) => void;
    processingWidth: number;
    processingHeight: number;
    hasInitializedDeps?: boolean;
    width?: number;
    height?: number;
    scope?: string;
}

interface VideoStreamProcessProps
    extends Partial<VideoRenderParams>,
        Required<ProcessorDeps> {
    hasInitialized: boolean;
    contentHint?: VideoContentHint;
}

const FEATURE_KEYS: [
    'backgroundBlurAmount',
    'bgImageUrl',
    'edgeBlurAmount',
    'flipHorizontal',
    'foregroundThreshold',
    'frameRate',
    'videoSegmentation',
    'videoSegmentationModel',
    'width',
    'height',
    'pan',
    'tilt',
    'zoom',
    'contentHint',
] = [
    'backgroundBlurAmount',
    'bgImageUrl',
    'edgeBlurAmount',
    'flipHorizontal',
    'foregroundThreshold',
    'frameRate',
    'videoSegmentation',
    'videoSegmentationModel',
    'width',
    'height',
    'pan',
    'tilt',
    'zoom',
    'contentHint',
];

type FeaturePropKeys = (typeof FEATURE_KEYS)[number];
type FeatureProps = Pick<Partial<VideoStreamProcessProps>, FeaturePropKeys>;

const getVideoConstraints = extractConstraintsWithKeys(FEATURE_KEYS);

export const updateFeatureProps = (
    constraints: MediaDeviceRequest['video'],
    props: FeatureProps,
) => {
    const extracted = getVideoConstraints(constraints);
    return FEATURE_KEYS.reduce((accm, key) => {
        switch (key) {
            case 'contentHint': {
                const [[feature] = []] = extracted[key];
                if (isVideoContentHint(feature) && props[key] !== feature) {
                    props[key] = feature;
                    return {...accm, [key]: feature};
                }
                return accm;
            }
            case 'videoSegmentation': {
                const [[feature] = []] = extracted[key];
                if (isRenderEffects(feature) && props[key] !== feature) {
                    props[key] = feature;
                    return {...accm, [key]: feature};
                }
                return accm;
            }
            case 'videoSegmentationModel': {
                const [[feature] = []] = extracted[key];
                if (isSegmentationModel(feature) && props[key] !== feature) {
                    props[key] = feature;
                    return {...accm, [key]: feature};
                }
                return accm;
            }
            case 'bgImageUrl': {
                const [[feature] = []] = extracted[key];
                if (feature) {
                    props[key] = feature;
                    return {...accm, [key]: feature};
                }
                return accm;
            }
            case 'width':
            case 'height':
            case 'frameRate':
            case 'foregroundThreshold':
            case 'edgeBlurAmount':
            case 'backgroundBlurAmount': {
                const [feature] = extracted[key];
                if (feature !== undefined) {
                    const value = getValueFromConstrainNumber(feature);
                    if (props[key] !== value) {
                        props[key] = value;
                        return {
                            ...accm,
                            [key]: value,
                        };
                    }
                }
                return accm;
            }
            case 'flipHorizontal':
            case 'pan':
            case 'tilt':
            case 'zoom': {
                const [feature] = extracted[key];
                if (feature !== undefined && props[key] !== feature) {
                    props[key] = feature;
                    return {...accm, [key]: feature};
                }
                return accm;
            }
        }
    }, {} as FeatureProps);
};

const applyFeatures = async (
    transformer: SegmentationTransform,
    features: FeatureProps,
) => {
    // bgImageUrl should be applied before `videoSegmentation` effects since
    // backgroundImage is the prerequisite of the `overlay` effects
    if (features.bgImageUrl) {
        await transformer.loadBackgroundImage(features.bgImageUrl);
    }
    Object.keys(features).forEach(key => {
        const k = key as keyof typeof features;
        switch (k) {
            case 'edgeBlurAmount':
            case 'foregroundThreshold': {
                const value = features[k];
                if (value !== undefined) {
                    transformer[k] = value;
                }
                return;
            }
            case 'backgroundBlurAmount': {
                const value = features[k];
                if (value !== undefined) {
                    transformer[k] = getBlurKernelSize(
                        value,
                        transformer.height,
                    );
                }
                return;
            }
            case 'videoSegmentation': {
                const value = features[k];
                if (value && value !== transformer.effects) {
                    transformer.effects = value;
                }
                return;
            }
            case 'flipHorizontal': {
                const value = features[k];
                if (
                    value !== undefined &&
                    value !== transformer.flipHorizontal
                ) {
                    transformer.flipHorizontal = value;
                }
                return;
            }
            default: {
                return;
            }
        }
    });
};

const adjustResolution = async (
    media: Media,
    features: FeatureProps,
    processingSize: Size,
) => {
    if (features.videoSegmentation) {
        const {
            video: [videoSettings],
        } = media.getSettings();
        const constraints = updateFeatureProps(media.constraints?.video, {});
        switch (features.videoSegmentation) {
            case 'blur':
            case 'overlay': {
                if (videoSettings?.height !== processingSize.height) {
                    try {
                        await media.applyConstraints({
                            video: {
                                width: processingSize.width,
                                height: processingSize.height,
                            },
                        });
                        const {
                            video: [postVideoSettings],
                        } = media.getSettings();
                        if (
                            postVideoSettings?.height !== processingSize.height
                        ) {
                            // Workaround Firefox 16:9 ratio https://bugzilla.mozilla.org/show_bug.cgi?id=1193640
                            await media.applyConstraints({
                                video: {height: 720},
                            });
                        }
                    } catch (error: unknown) {
                        // Workaround Firefox 16:9 ratio https://bugzilla.mozilla.org/show_bug.cgi?id=1193640
                        await media.applyConstraints({
                            video: {height: 720},
                        });
                    }
                }
                break;
            }
            case 'none': {
                if (
                    constraints.height &&
                    constraints.height !== videoSettings?.height
                ) {
                    await media.applyConstraints({
                        video: {
                            height: constraints.height,
                        },
                    });
                }
                break;
            }
        }
    }
};

const getTrackProcessor = (
    shouldUseStreamTrackProcessor: boolean,
    ...params: Parameters<typeof createVideoTrackProcessorWithFallback>
) => {
    if (
        shouldUseStreamTrackProcessor &&
        'MediaStreamTrackProcessor' in window
    ) {
        return createVideoTrackProcessor();
    }
    return createVideoTrackProcessorWithFallback(...params);
};

export const createVideoStreamProcess = ({
    trackProcessorAPI = () => 'stream',
    processingWidth,
    processingHeight,
    shouldEnable,
    frameRate,
    //backgroundBlurAmount,
    videoSegmentation,
    //edgeBlurAmount,
    foregroundThreshold,
    bgImageUrl,
    flipHorizontal,
    edgeBlurAmount,
    scope = 'media',
    ...options
}: VideoStreamProcessOptions): Process<Promise<Media>> => {
    const videoSegmentationModel =
        options.videoSegmentationModel ?? 'mediapipeSelfie';
    const backgroundBlurAmount =
        options.backgroundBlurAmount &&
        getBlurKernelSize(options.backgroundBlurAmount, processingHeight);
    const transformer =
        options.transformer ??
        createCanvasTransform(options.segmenters[videoSegmentationModel], {
            width: processingWidth,
            height: processingHeight,
            effects: videoSegmentation,
            foregroundThreshold,
            backgroundBlurAmount,
            edgeBlurAmount,
            flipHorizontal,
        });
    const proxy = proxyWithLog(logger, scope);
    const props: VideoStreamProcessProps = {
        videoSegmentationModel,
        segmenters: {
            mediapipeSelfie: proxy(
                options.segmenters.mediapipeSelfie,
                'Segmenter',
            ),
        },
        transformer: proxy(transformer, 'Transformer'),
        videoProcessor: () =>
            proxy(
                createVideoProcessor(
                    [transformer],
                    getTrackProcessor(trackProcessorAPI() === 'stream', {
                        width: processingWidth,
                        height: processingHeight,
                        frameRate,
                    }),
                ),
                'VideoProcessor',
            ),
        videoSegmentation,
        backgroundBlurAmount,
        edgeBlurAmount,
        foregroundThreshold,
        frameRate,
        bgImageUrl,
        flipHorizontal,
        hasInitialized: options.hasInitializedDeps ?? false,
    };

    return async mediaP => {
        const media = await mediaP;
        const features = updateFeatureProps(media.constraints?.video, props);
        const shouldEnabled = shouldEnable();
        if (!shouldEnabled || !media.stream?.getVideoTracks().length) {
            logger.debug(
                {scope, features, shouldEnabled},
                'Video processing is skipped',
            );
            return media;
        }
        try {
            if (!props.hasInitialized) {
                await props.videoProcessor().open();
                props.hasInitialized = true;
            }
            await applyFeatures(props.transformer, features);
            await adjustResolution(media, features, {
                width: processingWidth,
                height: processingHeight,
            });
            if (
                features.videoSegmentationModel &&
                features.videoSegmentationModel !==
                    props.transformer.segmenter.model
            ) {
                props.transformer.segmenter =
                    props.segmenters[features.videoSegmentationModel];
            }
            const stream = await props.videoProcessor().process(media.stream);
            const release = async () => {
                props.videoProcessor().close();
                await media.release();
                props.hasInitialized = false;
            };
            const muteAudio = (mute: boolean) => {
                media.muteAudio(mute);
                muteStreamTrack(stream)(mute, 'audio');
            };
            const muteVideo = (mute: boolean) => {
                media.muteVideo(mute);
                props.transformer.effects = mute
                    ? 'none'
                    : props.videoSegmentation ?? 'none';
                muteStreamTrack(stream)(mute, 'video');
            };

            const applyConstraints: Media['applyConstraints'] =
                applyExtendedConstraints(media, async constraints => {
                    if (isEmpty(constraints.video)) {
                        return;
                    }
                    const features = updateFeatureProps(
                        constraints.video,
                        props,
                    );
                    logger.debug(
                        {scope, constraints: constraints.video, features},
                        'apply video constraints',
                    );
                    if (isEmpty(features)) {
                        return;
                    }
                    try {
                        await applyFeatures(props.transformer, features);
                        await adjustResolution(media, features, {
                            width: processingWidth,
                            height: processingHeight,
                        });
                        if (
                            features.videoSegmentationModel &&
                            features.videoSegmentationModel !==
                                props.transformer.segmenter.model
                        ) {
                            props.transformer.segmenter =
                                props.segmenters[
                                    features.videoSegmentationModel
                                ];
                        }
                    } catch (error: unknown) {
                        if (error instanceof Error) {
                            logger.error(
                                {
                                    scope,
                                    constraints: constraints.video,
                                    features,
                                    error,
                                },
                                'failed to apply video constraints',
                            );
                            options.onError?.(error);
                        }
                    }
                });
            const prevGetSettings = media.getSettings;
            return wrapToJSON(
                shallowCopy(media, {
                    stream,
                    muteAudio,
                    muteVideo,
                    applyConstraints,
                    release,
                    getSettings: () => {
                        const {audio, video} = prevGetSettings();
                        const contentHint =
                            (stream.getAudioTracks().at(0)
                                ?.contentHint as VideoContentHint) ?? '';
                        const videoSettings = {
                            videoSegmentation: transformer.effects,
                            foregroundThreshold:
                                transformer.foregroundThreshold,
                            // Background blur amount is a function of height
                            backgroundBlurAmount: props.backgroundBlurAmount,
                            edgeBlurAmount: transformer.edgeBlurAmount,
                            flipHorizontal: transformer.flipHorizontal,
                            bgImageUrl:
                                transformer.backgroundImage && props.bgImageUrl,
                            videoSegmentationModel: transformer.segmenter.model,
                            pan: props.pan,
                            tilt: props.tilt,
                            zoom: props.zoom,
                            contentHint,
                        };
                        const settings = {
                            audio,
                            video: video.map(settings => ({
                                ...settings,
                                ...videoSettings,
                            })),
                        };
                        logger.debug(
                            {scope, settings: videoSettings},
                            'get video processor settings',
                        );
                        return settings;
                    },
                }),
            );
        } catch (e: unknown) {
            if (e instanceof Error) {
                options.onError?.(e);
            }
            return media;
        }
    };
};
