import type {
    Segmenter,
    Segmentation,
    ProcessStatus,
    ProcessInputType,
    SegmentationModel,
    AsyncAssets,
} from '../types';
import {toSegmentation, createInputImageConvertor, ensure} from '../utils';
import {createCanvasRenderUtils} from '../canvasRenderUtils';
import {PROCESSING_WIDTH, PROCESSING_HEIGHT} from '../constants';
import {loadScript, loadTfjsCore, loadTfjsBackendWebGl} from '../load';

export const SelfieSegmentationModelTypes: ['general', 'landscape'] = [
    'general',
    'landscape',
];
export type SelfieSegmentationModelType =
    (typeof SelfieSegmentationModelTypes)[number];

interface Options extends AsyncAssets {
    modelType: SelfieSegmentationModelType;
    processingWidth: number;
    processingHeight: number;
    gluePath: string;
    selfieMode: boolean;
    prodMode: boolean;
}

interface Props extends AsyncAssets {
    segmenter?: SelfieSegmentation;
    segmentation: Segmentation[];
    selfieMode: boolean;
    renderUtils: ReturnType<typeof createCanvasRenderUtils>;
    status: ProcessStatus;
}

export const createSegmenter = (
    basePath = '/',
    {
        modelType = 'general',
        tfjsCoreLoaded = false,
        tfjsBackendLoaded = false,
        glueLoaded = false,
        processingWidth = PROCESSING_WIDTH,
        processingHeight = PROCESSING_HEIGHT,
        gluePath = '',
        selfieMode = false,
        prodMode = true,
    }: Partial<Options> = {},
): Segmenter => {
    const props: Props = {
        selfieMode,
        segmentation: [],
        renderUtils: createCanvasRenderUtils(processingWidth, processingHeight),
        status: 'created',
        tfjsCoreLoaded,
        tfjsBackendLoaded,
        glueLoaded,
    };

    const toInputImage = createInputImageConvertor(input =>
        props.renderUtils.drawAndBlurImageOnOffScreenCanvas({
            image: input,
            blurAmount: 0,
            offscreenCanvasName: 'blurredCanvas',
        }),
    );

    const segmentPerson = async (input: ProcessInputType) => {
        const segmenter = ensure(props.segmenter);
        props.status = 'processing';
        const image = await toInputImage(input);
        await segmenter.send({image});
        props.status = 'idle';
    };

    return {
        get model() {
            return 'mediapipeSelfie' as SegmentationModel;
        },
        get width() {
            return processingWidth;
        },
        get height() {
            return processingHeight;
        },
        get status() {
            return props.status;
        },

        open: async () => {
            props.status = 'opening';
            // Avoid side-effects from the modules
            if (!props.tfjsCoreLoaded) {
                await loadTfjsCore(prodMode);
                props.tfjsCoreLoaded = true;
            }
            if (!props.tfjsBackendLoaded) {
                await loadTfjsBackendWebGl();
                props.tfjsBackendLoaded = true;
            }
            if (!props.glueLoaded) {
                await loadScript(gluePath, gluePath);
                props.glueLoaded = true;
            }
            props.segmenter = new SelfieSegmentation({
                locateFile: (path: string) =>
                    `${basePath.replace(/\/+$/, '')}/${path}`,
            });
            const modelSelection = modelType === 'landscape' ? 1 : 0;
            props.segmenter.setOptions({
                modelSelection,
                selfieMode: props.selfieMode,
            });
            props.segmenter.onResults(results => {
                props.segmentation = [
                    toSegmentation(
                        results.segmentationMask,
                        'canvasimagesource',
                        () => 'person',
                    ),
                ];
            });
            await props.segmenter.initialize();
            props.status = 'opened';
        },

        process: async input => {
            await segmentPerson(input);
            return props.segmentation;
        },

        close: () => {
            props.segmenter?.reset();
            props.status = 'closed';
        },

        destroy: async () => {
            props.status = 'destroying';
            await props.segmenter?.close();
            props.status = 'destroyed';
        },
    };
};
