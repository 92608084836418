import type {BlockedBrowserPermissionsInfoType} from '@pexip/media-components/src/types';

export enum PreflightJoinDetailsToRender {
    BlockedDeviceSelection = 'blocked-device-selection',
    DeviceSelection = 'device-selection',
    Spinner = 'spinner',
}

export interface Idp {
    name: string;
    uuid: string;
    redirectUrl?: string;
    img?: string;
}

export enum FlowType {
    Express = 'express',
    StepByStep = 'step-by-step',
}

export interface BlockedBrowserPermissionsInfo {
    // vp9 is a webm file format
    vp9: string | undefined;
    mp4: string | undefined;
    browserName: string;
    link?: string;
    helpSteps?: Array<{step: number; text: string}>;
    videoTextOverlaySteps?: Array<{
        step: number;
        text: string;
        duration: number;
    }>;
    permissionInfoType: BlockedBrowserPermissionsInfoType;
}

export interface BrowserDetection<T> {
    onChromeOnMobile: () => T;
    onChromeOnDesktop: () => T;
    onFirefoxOnDesktop: () => T;
    onEdgeOnMobile: () => T;
    onEdgeOnDesktop: () => T;
    onSafariOnIPhone: () => T;
    onSafariOnIpad: () => T;
    onSafariOnMacOs: () => T;
    onOtherBrowser: () => T;
}

export type BrowserType =
    | 'android'
    | 'chrome'
    | 'edge'
    | 'firefox'
    | 'safari-ios'
    | 'safari-ipados'
    | 'safari-macosx'
    | 'other';

export interface PreflightTipsInfo {
    step: number;
    text: React.ReactNode;
}

export enum MeetingFlow {
    Loading,
    AreYouHost,
    EnterPin,
    EnterHostPin,
    EnterExtension,
    Idp,
    InMeeting,
    Error,
}

export type TestIdValue = string;
export type GetState<T, K extends keyof T> = () => Pick<T, K>;
export type Subscribe = (onChange: () => void) => () => void;
export enum StepByStepCompletion {
    Uncompleted = 'uncompleted',
    Completed = 'completed',
    Skipped = 'skipped',
    Reviewing = 'reviewing',
}
