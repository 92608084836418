import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import type {ButtonVariant, MenuContent} from '@pexip/components';
import {
    AboutModal,
    Menu,
    UserMenuDropContent,
    Text,
    NotificationTooltip,
} from '@pexip/components';
import type {AutoHideButtonCallbacks} from '@pexip/media-components';

import {TestId} from '../../../test/testIds';
import {useBranding} from '../../branding/Context';
import {skippedStepByStepSignal} from '../../signals/StepByStep.signals';
import {useConfig} from '../../config';
import {StepByStepCompletion} from '../../types';

export const UserMenuView: React.FC<{
    menuContent: MenuContent;
    autoHideProps?: AutoHideButtonCallbacks;
    version: string;
    pluginApiVersion: string;
    variant?: ButtonVariant;
    isAboutOpen: boolean;
    setAboutOpen: React.Dispatch<React.SetStateAction<boolean>>;
    termsOfServiceUrl?: string;
}> = ({
    autoHideProps,
    isAboutOpen,
    menuContent,
    version,
    pluginApiVersion,
    setAboutOpen,
    termsOfServiceUrl,
}) => {
    const {t} = useTranslation();
    const overlay = useBranding('overlay');
    const [
        isTestVideoAndSoundTooltipVisible,
        setIsTestVideoAndSoundTooltipVisible,
    ] = useState(false);
    const [_, setStepByStepCompletion] = useConfig('stepByStepCompletion');

    useEffect(
        () =>
            skippedStepByStepSignal.add(() => {
                setIsTestVideoAndSoundTooltipVisible(true);
            }),
        [],
    );

    return (
        <>
            <NotificationTooltip
                position="bottomLeft"
                header={t(
                    'test-video-and-sound-menu-tooltip.title',
                    'Test video and sound',
                )}
                text={t(
                    'test-video-and-sound-menu-tooltip.description',
                    'You can test your video and sound anytime from the menu',
                )}
                controlledIsVisible={isTestVideoAndSoundTooltipVisible}
                controlledOnChange={setIsTestVideoAndSoundTooltipVisible}
                ctaFn={() => {
                    setIsTestVideoAndSoundTooltipVisible(false);
                    setStepByStepCompletion(StepByStepCompletion.Reviewing);
                }}
                ctaText="Test now"
            >
                <UserMenuDropContent
                    position="bottomRight"
                    content={
                        <Menu
                            testId={TestId.UserMenu}
                            menuContent={menuContent}
                        />
                    }
                    data-testid={TestId.UserMenuButton}
                    modifier="square"
                    colorScheme={overlay}
                    variant="translucent"
                    {...autoHideProps}
                />
            </NotificationTooltip>
            <AboutModal
                isOpen={isAboutOpen}
                onClose={() => setAboutOpen(false)}
                version={version}
                data-testid={TestId.UserMenuAboutPexipModal}
                headingText={t('common.about-pexip', 'About Pexip')}
                privacyPolicyUrl=""
                privacyPolicyLinkText=""
                termsOfServiceUrl={termsOfServiceUrl}
                termsOfServiceLinkText={t(
                    'common.terms-of-service',
                    'Terms of Service',
                )}
                aboutTextEnhancerEnd={
                    <Text>Plugin API Version: {pluginApiVersion}</Text>
                }
            />
        </>
    );
};

export type UserMenuViewProps = React.ComponentProps<typeof UserMenuView>;
